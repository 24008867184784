import { Avatar, Box, Link, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicesAnalytics } from 'shared/analytics/invoices';

import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import {
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
} from '@/services/webStorage/localStorage';
import { COLOR } from '@/theme/colors';

import { ButtonState, Props } from './types';

function AccountingProvidersListItem({
    disconnectMessage,
    onDisconnectButtonClick,
    provider,
}: Props) {
    const { t } = useTranslation();

    const { providerSvgButton, onClick } = useMemo(() => {
        let state: ButtonState = 'not_connected';

        if (provider.isConnected) {
            if (disconnectMessage) {
                state = 'lost_connection';
            } else {
                state = 'connected';
            }
        }

        if (state === 'connected') {
            return {
                providerSvgButton: provider.disconnectIcon,
                onClick: onDisconnectButtonClick,
            };
        }

        if (provider.isAvailable) {
            return {
                providerSvgButton: provider.connectIcon,
                onClick: provider.connect,
            };
        }

        return {
            providerSvgButton: null,
            onClick: null,
        };
    }, [
        disconnectMessage,
        onDisconnectButtonClick,
        provider.isAvailable,
        provider.connect,
        provider.connectIcon,
        provider.disconnectIcon,
        provider.isConnected,
    ]);

    useEffect(() => {
        if (
            provider.isConnected &&
            getItemFromLocalStorage(
                WEB_STORAGE_KEYS.user_tried_to_connect_to_xero,
            )
        ) {
            InvoicesAnalytics.logUserConnectedWithAccountingProvider(
                FirebaseAnalytics.logEvent,
            );

            if (provider.type === 'xero') {
                InvoicesAnalytics.logUserConnectedWithXero(
                    FirebaseAnalytics.logEvent,
                );
            } else if (provider.type === 'quick_books') {
                InvoicesAnalytics.logUserConnectedWithQuickbooks(
                    FirebaseAnalytics.logEvent,
                );
            }

            removeItemFromLocalStorage(
                WEB_STORAGE_KEYS.user_tried_to_connect_to_xero,
            );
        }
    }, [provider.isConnected, provider.type]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: 2,
            }}
        >
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Avatar
                    alt={`Accounting provider logo - ${provider.title}`}
                    src={provider.image}
                    sx={{ height: 70, width: 70 }}
                />
                <Box>
                    <Typography
                        fontWeight="bold"
                        sx={{ mb: 1 }}
                        variant="body2"
                    >
                        {provider.title}
                        <Typography
                            component="span"
                            fontWeight="500"
                            variant="body2"
                        >
                            {` - ${provider.subtitle}`}
                        </Typography>
                    </Typography>
                    <Typography color={COLOR.regentGray} variant="body2">
                        {provider.description}
                    </Typography>
                    <Link
                        href={provider.website.url}
                        rel="noopener noreferrer"
                        sx={{
                            color: COLOR.deepCerulean,
                            textDecoration: 'none',
                        }}
                        target="_blank"
                        variant="body2"
                    >
                        {provider.website.text}
                    </Link>
                    {provider.isConnected && provider.connectedTo ? (
                        <Box className="flex items-center text-[0.875rem] gap-1 mt-1">
                            <Typography variant="body2">
                                {t('Invoicing:connected_to')}
                            </Typography>
                            <Box className="px-2 py-1 rounded-[8px] bg-[#1fc0e7]">
                                <Typography
                                    variant="body2"
                                    className="text-white"
                                >
                                    {provider.connectedTo}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            </Box>

            {providerSvgButton && onClick ? (
                <Box
                    onClick={onClick}
                    sx={{ alignSelf: 'center', cursor: 'pointer' }}
                >
                    {providerSvgButton}
                </Box>
            ) : null}
        </Box>
    );
}

export default AccountingProvidersListItem;
