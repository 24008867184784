import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Box, Checkbox, tooltipClasses, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'shared/utils/moment';

import { TickIcon } from '@/assets/svg';
import ReadTickIcon from '@/assets/svg/ReadTickIcon';
import { Tooltip } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useNetwork } from '@/hooks';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import TooltipSyncButton from '../TooltipButton';

import { Props } from './types';

function AppointmentInviteContactSection({
    item,
    onSmsClick,
    onEmailClick,
    onEmailDisabledClick,
    onSmsDisabledClick,
    state,
    disable = false,
    mode = 'edit',
}: Props) {
    const { t } = useTranslation();

    const { isSyncInProgress } = useDBSyncContext();

    const isOnline = useNetwork();

    const contactState = useMemo(
        () =>
            state[item.id] ?? {
                smsStatus: 'not_sent',
                emailStatus: 'not_sent',
                smsAt: null,
                emailAt: null,
                sms: false,
                email: false,
            },
        [item.id, state],
    );

    const isSmsSending = contactState.smsStatus === 'sending';
    const isEmailSending = contactState.emailStatus === 'sending';

    const isSmsSent = contactState.smsStatus === 'sent';
    const isEmailSent = contactState.emailStatus === 'sent';

    const isSmsNotSent = contactState.smsStatus === 'not_sent';
    const isEmailNotSent = contactState.emailStatus === 'not_sent';

    const isSmsDisabled = !item.phone || (disable && isSmsNotSent);
    const isEmailDisabled = !item.email || (disable && isEmailNotSent);

    const isEditMode = mode === 'edit';

    const smsSentAt = useMemo(
        () =>
            contactState.smsAt
                ? moment(contactState.smsAt).format('MMMM Do H[:]mm a')
                : '',
        [contactState.smsAt],
    );

    const emailSentAt = useMemo(
        () =>
            contactState.emailAt
                ? moment(contactState.emailAt).format('MMMM Do H[:]mm a')
                : '',
        [contactState.emailAt],
    );

    const handleSmsClick = useCallback(
        (event) => {
            if (!isSmsSending && !isSmsSent) {
                event.stopPropagation();
            }
            if (isSmsDisabled) {
                onSmsDisabledClick(item);
            } else if (!isSmsSending && !isSmsSent) {
                !disable && onSmsClick(item);
            }
        },
        [
            isSmsDisabled,
            isSmsSending,
            isSmsSent,
            disable,
            onSmsDisabledClick,
            item,
            onSmsClick,
        ],
    );

    const handleEmailClick = useCallback(
        (event) => {
            if (!isEmailSent && !isEmailSending) {
                event.stopPropagation();
            }
            if (isEmailDisabled) {
                onEmailDisabledClick(item);
            } else if (!isEmailSent && !isEmailSending) {
                !disable && onEmailClick(item);
            }
        },
        [
            isEmailDisabled,
            isEmailSent,
            isEmailSending,
            disable,
            onEmailDisabledClick,
            item,
            onEmailClick,
        ],
    );

    const renderActionElement = useCallback(
        (type: 'sms' | 'email') => {
            const isTypeSms = type === 'sms';

            const status = isTypeSms
                ? contactState.smsStatus
                : contactState.emailStatus;

            return status === 'not_sent' ? (
                isEditMode ? (
                    <Checkbox
                        checked={
                            isTypeSms ? contactState.sms : contactState.email
                        }
                        disabled={isTypeSms ? isSmsDisabled : isEmailDisabled}
                        size="small"
                        className="ml-[-9px] mr-[-6px]"
                    />
                ) : (
                    <Box className="py-[9px]">
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            showTooltip={
                                (isTypeSms
                                    ? !isSmsDisabled
                                    : !isEmailDisabled) && isSyncInProgress
                            }
                            onClick={
                                isTypeSms ? handleSmsClick : handleEmailClick
                            }
                            size="small"
                            variant="outlined"
                            sx={{ color: ENTITY_COLOR.schedule }}
                            disabled={
                                isSyncInProgress ||
                                (isTypeSms ? isSmsDisabled : isEmailDisabled)
                            }
                            startIcon={
                                !isTypeSms ? (
                                    <EmailIcon
                                        htmlColor={
                                            isEmailDisabled || isSyncInProgress
                                                ? COLOR.ghost
                                                : ENTITY_COLOR.schedule
                                        }
                                        className="w-[20px] h-[20px]"
                                    />
                                ) : (
                                    <SmartphoneIcon
                                        htmlColor={
                                            isSmsDisabled || isSyncInProgress
                                                ? COLOR.ghost
                                                : ENTITY_COLOR.schedule
                                        }
                                        className="w-[20px] h-[20px]"
                                    />
                                )
                            }
                        >
                            {t(
                                isTypeSms
                                    ? 'Event:invite_contact:invite_via_sms'
                                    : 'Event:invite_contact:invite_via_email',
                            )}
                        </TooltipSyncButton>
                    </Box>
                )
            ) : status === 'sent' ? (
                <Box className="mr-1 py-[9px]">
                    <ReadTickIcon
                        width={20}
                        height={20}
                        color={COLOR.cozumel}
                    />
                </Box>
            ) : isOnline ? (
                <Box className="py-[9px]">
                    <TickIcon color={COLOR.ghost} width={20} height={20} />
                </Box>
            ) : (
                <Box className="py-[9px]">
                    <SyncDisabledIcon
                        className="w-[20px] h-[20px]"
                        htmlColor={COLOR.heartPurple}
                    />
                </Box>
            );
        },
        [
            contactState.email,
            contactState.emailStatus,
            contactState.sms,
            contactState.smsStatus,
            handleEmailClick,
            handleSmsClick,
            isEditMode,
            isEmailDisabled,
            isOnline,
            isSmsDisabled,
            isSyncInProgress,
            t,
        ],
    );

    const smsContent = useMemo(
        () => (
            <Box
                className={clsx('flex items-center', {
                    'cursor-pointer': isSmsDisabled || isSmsNotSent,
                })}
                onClick={handleSmsClick}
            >
                {renderActionElement('sms')}
                {isEditMode || !isSmsNotSent ? (
                    <>
                        <SmartphoneIcon
                            htmlColor={
                                (isSmsDisabled || isSmsSending) && isOnline
                                    ? COLOR.ghost
                                    : isSmsSending
                                    ? COLOR.heartPurple
                                    : isSmsSent
                                    ? COLOR.cozumel
                                    : undefined
                            }
                            className="mr-[4px] w-[20px] h-[20px]"
                        />
                        <Typography
                            variant="body2"
                            className={clsx({
                                'text-ghost':
                                    isSmsDisabled || (isSmsSending && isOnline),
                            })}
                            fontSize={isSmsSending ? 12 : 14}
                        >
                            {t(
                                isOnline && isSmsSending
                                    ? 'Event:invite_contact:sending'
                                    : isSmsSending
                                    ? 'Event:invite_contact:pending'
                                    : 'Event:invite_contact:sms',
                            )}
                        </Typography>
                    </>
                ) : null}
            </Box>
        ),
        [
            isSmsDisabled,
            isSmsNotSent,
            handleSmsClick,
            renderActionElement,
            isEditMode,
            isSmsSending,
            isOnline,
            isSmsSent,
            t,
        ],
    );

    const emailContent = useMemo(
        () => (
            <Box
                className={clsx('flex items-center', {
                    'cursor-pointer': isEmailDisabled || isEmailNotSent,
                })}
                onClick={handleEmailClick}
            >
                {renderActionElement('email')}
                {isEditMode || !isEmailNotSent ? (
                    <>
                        <EmailIcon
                            htmlColor={
                                (isEmailDisabled || isEmailSending) && isOnline
                                    ? COLOR.ghost
                                    : isEmailSending
                                    ? COLOR.heartPurple
                                    : isEmailSent
                                    ? COLOR.cozumel
                                    : undefined
                            }
                            className="mr-[4px] w-[20px] h-[20px]"
                        />
                        <Typography
                            variant="body2"
                            fontSize={isEmailSending ? 12 : 14}
                            className={clsx({
                                'text-ghost':
                                    (isEmailDisabled || isEmailSending) &&
                                    isOnline,
                            })}
                        >
                            {t(
                                isOnline && isEmailSending
                                    ? 'Event:invite_contact:sending'
                                    : isEmailSending
                                    ? 'Event:invite_contact:pending'
                                    : 'Event:invite_contact:email',
                            )}
                        </Typography>
                    </>
                ) : null}
            </Box>
        ),
        [
            isEmailDisabled,
            isEmailNotSent,
            handleEmailClick,
            renderActionElement,
            isEditMode,
            isEmailSending,
            isOnline,
            isEmailSent,
            t,
        ],
    );

    return (
        <Box
            className={clsx('flex items-center gap-2 mb-[5px]', {
                'ml-[91px]': !isEditMode,
                'ml-4': isEditMode,
            })}
        >
            {isEditMode ? (
                <Typography noWrap className="font-bold mr-2" variant="body2">
                    {t('Event:invite_contact:title')}
                </Typography>
            ) : null}
            {item.phone ? isSmsSent || (isSmsSending && !isOnline) ? (
                <Tooltip
                    title={t(
                        isSmsSending && !isOnline
                            ? 'Event:invite_contact:offline_tooltip'
                            : 'Event:invite_contact:sms_tooltip',
                        {
                            phone: `${item.phonePrefix ?? ''}${item.phone}`,
                            date: smsSentAt,
                        },
                    )}
                    placement="bottom"
                    PopperProps={{
                        sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                                maxWidth: 'min-content',
                                minWidth: 300,
                            },
                        },
                    }}
                >
                    {smsContent}
                </Tooltip>
            ) : (
                smsContent
            ): null}
            {item.email ? isEmailSent || (isEmailSending && !isOnline) ? (
                <Tooltip
                    title={t(
                        isEmailSending && !isOnline
                            ? 'Event:invite_contact:offline_tooltip'
                            : 'Event:invite_contact:email_tooltip',
                        {
                            email: item.email,
                            date: emailSentAt,
                        },
                    )}
                    placement="bottom"
                    PopperProps={{
                        sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                                maxWidth: 'min-content',
                                minWidth: 300,
                            },
                        },
                    }}
                >
                    {emailContent}
                </Tooltip>
            ) : (
                emailContent
            ) : null}
        </Box>
    );
}

export default AppointmentInviteContactSection;
