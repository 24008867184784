export type SalesCode = {
    provider: string;
    name: string;
    value: string;
    default: boolean;
};

export type ExternalAccountingProviderType = 'xero' | 'quick_books';

export type AccountingProviderType =
    | ExternalAccountingProviderType
    | 'internal';

export type AccountingProvider = {
    beta?: boolean;
    buttonText: string;
    connect: VoidFunction;
    description: string;
    image: string;
    showContactsSync?: boolean;
    subtitle: string;
    title: string;
    type: ExternalAccountingProviderType;
    website: {
        text: string;
        url: string;
    };
    connectIcon: JSX.Element | null;
    disconnectIcon: JSX.Element | null;
    connectedTo?: string | null;
};

export type AccountingProviderWeb = Omit<AccountingProvider, 'buttonText'> & {
    isConnected: boolean;
    isAvailable: boolean;
};

export type AccountingProviderStatus = {
    accountingDisconnectMessage: string | null;
    accountingProvider: string | null;
    lockAccountingProvider: boolean;
};

export type ExportInvoicesFormatType = 'xero' | 'quickbooks' | 'internal';

export type ExportInvoicesFormatItem = {
    image: string;
    translationKeys: {
        description: string;
        subtitle: string;
        title: string;
    };
    type: ExportInvoicesFormatType;
};
export enum EXPORT_INVOICE_VISIBILITY {
    HIDDEN,
    SELECTING_PHASE,
    EXPORTING_PHASE,
}
