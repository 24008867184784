import {
    Alert,
    Box,
    Button,
    Divider,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { of } from 'rxjs';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import connectToQuickbooksIcon from 'shared/assets/images/invoicing/connectToQuickbooks.png';
import quickbooksLogo from 'shared/assets/images/invoicing/quickbooks.png';
import xeroLogo from 'shared/assets/images/invoicing/xero.png';
import DatabaseService from 'shared/db/services/Database.web';
import Invoice from 'shared/db/services/Invoice';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import User from 'shared/db/services/User';
import {
    AccountingProviderStatus,
    AccountingProviderWeb,
    SalesCode,
} from 'shared/types/Invoicing';
import { isAccountingProviderType } from 'shared/utils/typeGuards';

import { XeroConnectButtonIcon, XeroDisconnectButtonIcon } from '@/assets/svg';
import { AlertDialog, BackLinkButton } from '@/components';
import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { withSyncContext } from '@/hoc';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { AccountingProvidersAPI } from '@/services/networking/accountingProviders';
import { Snackbar } from '@/services/toastNotifications';
import { storeItemInLocalStorage } from '@/services/webStorage/localStorage';
import { COLOR } from '@/theme/colors';

import {
    AccountingProvidersList,
    AccountingProviderSalesCodeSelect,
} from './components';
import { Props, DialogModalsState, DialogType } from './types';

function AccountingProvidersPage({
    invoicesUnsyncedWithProviderCount,
    organisationAccountings,
}: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();
    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();
    const { t } = useTranslation();

    const [organisationAccountingsData] = organisationAccountings || [];
    const {
        accountingProvider,
        accountingDisconnectMessage = 'Error ocurred',
        lockAccountingProvider,
        salesAccountCode,
    } = organisationAccountingsData || {};

    const [dialogModalsState, setDialogModalsState] =
        useState<DialogModalsState>({
            disconnect_provider: { isOpen: false },
            import_contacts: { isOpen: false },
            sync_invoices: { isOpen: false },
        });
    const [isDataFetching, setIsDataFetching] = useState(true);
    const [isDataFetchingError, setIsDataFetchingError] = useState(false);
    const [isContactsImportRequestPending, setIsContactsImportRequestPending] =
        useState(false);
    const [isInvoicesSyncRequestPending, setIsInvoicesSyncRequestPending] =
        useState(false);
    const [salesCodes, setSalesCodes] = useState<SalesCode[]>([]);
    const [selectedSalesCode, setSelectedSalesCode] = useState('');
    const [orgName, setOrgName] = useState<string | null>(null);

    const database = useMemo(() => {
        return getDatabase();
    }, [getDatabase]);

    const organisationAccountingsService = useMemo(() => {
        return new OrganisationAccountings({
            database,
            logDBAction: Logger.logRecordActivity,
        });
    }, [database]);

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const openDialogModal = useCallback(
        (type: DialogType) => () => {
            setDialogModalsState((prev) => ({
                ...prev,
                [type]: { isOpen: true },
            }));
        },
        [],
    );

    const closeDialogModal = useCallback(
        (type: DialogType) => () => {
            setDialogModalsState((prev) => ({
                ...prev,
                [type]: { isOpen: false },
            }));
        },
        [],
    );

    const connectToXero = useCallback(async () => {
        try {
            setIsDataFetching(true);

            const data = await AccountingProvidersAPI.authorize('xero');

            window.location.href = data.payload.oauth_url;

            storeItemInLocalStorage(
                WEB_STORAGE_KEYS.user_tried_to_connect_to_xero,
                true,
            );
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });

            setIsDataFetching(false);
        }
    }, [t]);

    const connectToQuickbooks = useCallback(async () => {
        try {
            setIsDataFetching(true);

            const data = await AccountingProvidersAPI.authorize('quick_books');

            window.location.href = data.payload.oauth_url;

            storeItemInLocalStorage(
                WEB_STORAGE_KEYS.user_tried_to_connect_to_xero,
                true,
            );
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });

            setIsDataFetching(false);
        }
    }, [t]);

    const updateAccountingProviderData = useCallback(
        async (providerData: AccountingProviderStatus) => {
            await organisationAccountingsService.updateField(
                organisationAccountingsData.id,
                'accountingDisconnectMessage',
                providerData.accountingDisconnectMessage || null,
            );
            await organisationAccountingsService.updateField(
                organisationAccountingsData.id,
                'lockAccountingProvider',
                providerData.lockAccountingProvider || null,
            );
        },
        [organisationAccountingsData.id, organisationAccountingsService],
    );

    const disconnectAccountingProvider = useCallback(async () => {
        setIsDataFetching(true);

        await organisationAccountingsService.disconnectFromProvider(
            organisationAccountingsData.id,
        );

        setSalesCodes([]);
        setSelectedSalesCode('');

        setIsDataFetching(false);

        InvoicesAnalytics.logUserDisconnectedWithAccountingProvider(
            FirebaseAnalytics.logEvent,
        );

        if (accountingProvider === 'xero') {
            InvoicesAnalytics.logUserDisconnectedWithXero(
                FirebaseAnalytics.logEvent,
            );
        } else if(accountingProvider === 'quick_books') {
            InvoicesAnalytics.logUserDisconnectedWithQuickbooks(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [
        organisationAccountingsData.id,
        organisationAccountingsService,
        accountingProvider,
    ]);

    const handleSalesCodeChange = useCallback(
        (code: string) => {
            if (isSyncInProgress) {
                return;
            }

            setSelectedSalesCode(code);

            organisationAccountingsService.updateField(
                organisationAccountingsData.id,
                'salesAccountCode',
                code,
            );
        },
        [
            isSyncInProgress,
            organisationAccountingsData.id,
            organisationAccountingsService,
        ],
    );

    const fetchAccountingData = useCallback(async () => {
        try {
            setIsDataFetching(true);
            setIsDataFetchingError(false);

            const userStatusData =
                await AccountingProvidersAPI.getAccountingUserStatus();

            const {
                account_provider,
                accounting_disconnect_message,
                lock_accounting_provider,
                provider_organisation_name,
            } = userStatusData.payload;

            setOrgName(provider_organisation_name);

            const oldProviderData: AccountingProviderStatus = {
                accountingDisconnectMessage:
                    accountingDisconnectMessage || null,
                accountingProvider: accountingProvider || null,
                lockAccountingProvider,
            };

            const newProviderData: AccountingProviderStatus = {
                accountingDisconnectMessage: accounting_disconnect_message,
                accountingProvider: account_provider,
                lockAccountingProvider: lock_accounting_provider,
            };

            if (!isEqual(oldProviderData, newProviderData)) {
                await updateAccountingProviderData(newProviderData);
            }

            if (
                account_provider &&
                !accounting_disconnect_message &&
                isAccountingProviderType(account_provider)
            ) {
                const salesCodesData =
                    await AccountingProvidersAPI.getProviderSalesCodes(
                        account_provider,
                    );

                setSalesCodes(salesCodesData.payload);
            }
        } catch {
            setIsDataFetchingError(true);
        } finally {
            setIsDataFetching(false);
        }
    }, [
        accountingDisconnectMessage,
        accountingProvider,
        lockAccountingProvider,
        updateAccountingProviderData,
    ]);

    useEffect(() => {
        if (accountingProvider && salesCodes.length && !selectedSalesCode) {
            if (salesAccountCode) {
                handleSalesCodeChange(salesAccountCode);
            } else {
                const defaultSalesCode = salesCodes.find(
                    (salesCode) => salesCode.default,
                );

                handleSalesCodeChange(
                    defaultSalesCode?.value || salesCodes[0].value,
                );
            }
        }
    }, [
        accountingProvider,
        handleSalesCodeChange,
        salesAccountCode,
        salesCodes,
        selectedSalesCode,
    ]);

    const triggerContactsImport = useCallback(async () => {
        try {
            setIsContactsImportRequestPending(true);

            await AccountingProvidersAPI.triggerContactsImportFromXero();

            Snackbar.showToastNotification({
                message: t('Invoicing:import_contacts_message'),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        } finally {
            setIsContactsImportRequestPending(false);
        }
    }, [setIsContactsImportRequestPending, t]);

    const triggerInvoicesSync = useCallback(async () => {
        try {
            setIsInvoicesSyncRequestPending(true);

            await AccountingProvidersAPI.triggerInvoicesSyncToXero();

            Snackbar.showToastNotification({
                message: t('Invoicing:sync_success'),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        } finally {
            setIsInvoicesSyncRequestPending(false);
        }
    }, [setIsInvoicesSyncRequestPending, t]);

    useEffect(() => {
        if (!isInitialSyncInProgress && !isSyncInProgress) {
            fetchAccountingData();
        }
    }, [fetchAccountingData, isInitialSyncInProgress, isSyncInProgress]);

    useEffect(() => {
        if (accountingProvider) {
            userService.update(userProfileData?.id || '', {
                isInvoicingAccountSetupSet: true,
            });
        }
    }, [accountingProvider, userProfileData?.id, userService]);

    const providers = useMemo((): AccountingProviderWeb[] => {
        return [
            {
                connect: connectToXero,
                description: t('Invoicing:xero_description'),
                image: xeroLogo,
                isAvailable: accountingProvider === null,
                isConnected: accountingProvider === 'xero',
                connectedTo: accountingProvider === 'xero' ? orgName : null,
                showContactsSync: true,
                subtitle: t('Invoicing:xero_company'),
                title: t('Invoicing:xero_name'),
                type: 'xero',
                website: {
                    text: t('Invoicing:xero_website'),
                    url: 'https://xero.com',
                },
                connectIcon: <XeroConnectButtonIcon />,
                disconnectIcon: <XeroDisconnectButtonIcon />,
            },
            {
                connect: connectToQuickbooks,
                description: t('Invoicing:quickbooks_description'),
                image: quickbooksLogo,
                isAvailable: accountingProvider === null,
                isConnected: accountingProvider === 'quick_books',
                connectedTo: accountingProvider === 'quick_books' ? orgName : null,
                subtitle: t('Invoicing:quickbooks_company'),
                title: t('Invoicing:quickbooks_name'),
                type: 'quick_books',
                website: {
                    text: t('Invoicing:quickbooks_website'),
                    url: 'https://quickbooks.intuit.com',
                },
                connectIcon: <img
                alt={`Accounting provider logo - ${t('Invoicing:quickbooks_name')}`}
                src={connectToQuickbooksIcon}
                style={{ height: 43 }}
            />,
                disconnectIcon: <Button style={{color: COLOR.cancelRed}}>{`${t('Invoicing:disconnect')} ${t('Invoicing:quickbooks_name')}`}</Button>,
            },
        ];
    }, [accountingProvider, connectToXero, connectToQuickbooks, orgName, t]);

    const showProviderActions =
        !!accountingProvider && !accountingDisconnectMessage;

    const disableSalesCodesSelect =
        !salesCodes.length ||
        isSyncInProgress ||
        isDataFetching ||
        isDataFetchingError;

    const disableProvidersButtons =
        isDataFetching ||
        isSyncInProgress ||
        isDataFetchingError ||
        lockAccountingProvider;

    const disableActionsButtons =
        isDataFetching ||
        isSyncInProgress ||
        isDataFetchingError ||
        !!accountingDisconnectMessage;

    const disableImportContactsButton =
        disableActionsButtons || isContactsImportRequestPending;

    const disableSyncInvoicesButton =
        disableActionsButtons ||
        isInvoicesSyncRequestPending ||
        lockAccountingProvider ||
        invoicesUnsyncedWithProviderCount === 0;

    const SyncInvoicesButtonWrapper =
        invoicesUnsyncedWithProviderCount === 0 ? Tooltip : Box;

    return (
        <Box sx={{ pb: 4 }}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 4,
                }}
            >
                <BackLinkButton
                    to={ROUTE.settings}
                    text="Settings"
                    testID="AccountingProvidersPage-BackButton"
                />
            </Box>
            <Box sx={{ mb: 4 }}>
                <Typography fontSize={14} fontWeight="bold" sx={{ mb: 1 }}>
                    {t('Invoicing:title')}
                </Typography>
                <Typography fontSize={14} sx={{ mb: 2 }}>
                    {t('Invoicing:info')}
                </Typography>
                <Alert severity="info">
                    <Box sx={{ mb: 0.5 }}>{t('Invoicing:note')}</Box>
                    <Box>{t('Invoicing:disclaimer')}</Box>
                </Alert>
            </Box>
            {isDataFetchingError ? (
                <Alert severity="error" sx={{ mb: 4 }}>
                    {`${t('Invoicing:errors:data_fetching_error')} `}
                    <Link
                        color={COLOR.wineBerry}
                        component="button"
                        fontWeight={700}
                        sx={{ verticalAlign: 'unset' }}
                        onClick={fetchAccountingData}
                    >
                        {t('Generic:click_here')}
                    </Link>
                    {` ${t('Invoicing:errors:to_refetch_data')}`}
                </Alert>
            ) : null}
            {accountingDisconnectMessage ? (
                <Alert severity="error" sx={{ mb: 4 }}>
                    {accountingDisconnectMessage}
                </Alert>
            ) : null}
            <Box sx={{ mr: 6 }}>
                <AccountingProvidersList
                    disableButtons={disableProvidersButtons}
                    disconnectMessage={accountingDisconnectMessage || ''}
                    isLoading={isDataFetching}
                    onDisconnectButtonClick={openDialogModal(
                        'disconnect_provider',
                    )}
                    providers={providers}
                />
            </Box>
            {showProviderActions ? (
                <Box sx={{ flex: 1, mt: 4 }}>
                    <AccountingProviderSalesCodeSelect
                        disabled={disableSalesCodesSelect}
                        isLoading={isDataFetching}
                        onChange={handleSalesCodeChange}
                        salesCodes={salesCodes}
                        value={selectedSalesCode}
                    />
                </Box>
            ) : null}
            <Divider sx={{ my: 3 }} />
            {showProviderActions ? (
                <Box sx={{ display: 'flex' }}>
                    <Button
                        disabled={disableImportContactsButton}
                        onClick={openDialogModal('import_contacts')}
                        sx={{ mr: 2 }}
                        variant="text"
                    >
                        {t('Invoicing:import_contacts')}
                    </Button>
                    <SyncInvoicesButtonWrapper
                        title={t('Invoicing:all_invoices_synced') as string}
                    >
                        <div>
                            <Button
                                disabled={disableSyncInvoicesButton}
                                onClick={openDialogModal('sync_invoices')}
                                variant="text"
                            >
                                {t('Invoicing:sync_invoices')}
                            </Button>
                        </div>
                    </SyncInvoicesButtonWrapper>
                </Box>
            ) : null}
            {dialogModalsState.disconnect_provider.isOpen ? (
                <AlertDialog
                    close={closeDialogModal('disconnect_provider')}
                    message={t('Invoicing:dialog:disconnect_info')}
                    onConfirmButtonClick={disconnectAccountingProvider}
                    showCancelButton
                    testID="AccountingProvidersPage-DisconnectDialog"
                    title={t('Generic:are_you_sure')}
                />
            ) : null}
            {dialogModalsState.sync_invoices.isOpen ? (
                <AlertDialog
                    close={closeDialogModal('sync_invoices')}
                    message={t('Invoicing:confirm_sync')}
                    onConfirmButtonClick={triggerInvoicesSync}
                    showCancelButton
                    title={t('Actions:confirm')}
                    testID="AccountingProvidersPage-SyncInvoicesDialog"
                />
            ) : null}
            {dialogModalsState.import_contacts.isOpen ? (
                <AlertDialog
                    close={closeDialogModal('import_contacts')}
                    message={t('Invoicing:confirm_sync')}
                    onConfirmButtonClick={triggerContactsImport}
                    showCancelButton
                    title={t('Actions:confirm')}
                    testID="AccountingProvidersPage-ImportContactsDialog"
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = DatabaseService.getDatabase();

        const accountingsService = new OrganisationAccountings({
            database,
            logDBAction: Logger.logRecordActivity,
        });
        const invoicesService = new Invoice({
            database,
            imageService: new FirebaseImagesService(),
            logDBAction: Logger.logRecordActivity,
        });

        return {
            organisationAccountings: isInitialSyncInProgress
                ? of()
                : accountingsService.get(),
            invoicesUnsyncedWithProviderCount: isInitialSyncInProgress
                ? of()
                : invoicesService
                      .getInvoicesUnsyncedWithProvider()
                      .observeCount(),
        };
    },
);

export default withSyncContext<Props>(enhance(AccountingProvidersPage));
